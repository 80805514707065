@import '../../styles/variable.scss';
@import '../../styles/mixin.scss';
@import '../../styles/extend.scss';



.wrap {
    @extend %wrap;
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
    // background-color: $black;
    background-image: linear-gradient(to bottom, rgba($black, 0.5), rgba($black, 0.8));

    color: $white;
    margin-bottom: 0;

    .row {
        @extend %row;

        @include media-breakpoint-down(sm) {}
    }

    h2 {
        font-weight: 700;
        font-size: 2.4rem;
        margin-bottom: 3.2rem;
    }
}

.postWrap {

    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 2rem;
    grid-row-gap: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;

    @include media-breakpoint-down(lg) {

        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }

    @include media-breakpoint-down(sm) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}

.post {
    display: inline-block;
    max-width: 20rem;
    width: 100%;
    margin: auto;

    @extend %hoverZoomOutImg;


    @include media-breakpoint-down(lg) {
        margin: 0 auto 2rem;
    }

    @include media-breakpoint-down(sm) {
        // margin: 2rem 0.6rem;
        // max-width: 16rem;
        margin: auto;
    }

    // display: inline-block;

    a {
        display: flex;
        flex-direction: column;
        text-decoration: none;
        color: $black;
        font-size: 1.4rem;
    }

    .imageWrap {
        overflow: hidden;
        padding-bottom: 100%;
        position: relative;
        margin-bottom: .8rem;
    }

    img {
        position: absolute;
        // top: 50%;
        left: 0;
        width: 100%;
        // height: 100%;
        // transform: translateY(-50%);
        object-fit: cover;
    }

    .sub {
        display: flex;
        // justify-content: space-around;
        justify-content: flex-end;
        // color: $darkGray;
        color: $white;
        // opacity: .95;
        margin-bottom: .8rem;
    }

    .source {
        // @extend %price;
        font-size: 1rem;
        max-width: 16rem;
        // overflow: hidden;

        &:before {
            content: "ig:";
            padding-right: .4rem;
        }
    }
}