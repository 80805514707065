@import '../styles/variable.scss';
@import '../styles/mixin.scss';
@import '../styles/extend.scss';

.wrap {
    @extend %wrap;
    position: relative;

    padding-top: 4rem;
    padding-bottom: 3.2rem;
    // margin-top: 2rem;
    margin-bottom: 0rem;

    .row {
        @extend %row;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        // flex-direction: column;
        padding-left: 4rem;
        padding-right: 4rem;
    }

    h1 {
        position: relative;
        font-size: 2.4rem;
        line-height: 1.67;
        color: $black;
        margin-top: 1.2rem;
        margin-bottom: 3.2rem;
        text-align: center;
        text-shadow: 1px 1px 1px $gray;

        span {
            // display: inline-block;
            position: absolute;
            top: -2.6rem;
            // right: -3rem;
            color: $lightGray;
            z-index: -1;
            font-size: 3.6rem;
            // text-shadow: 1px 1px 1px $gray;
            transform: translateX(-6rem);

            @include media-breakpoint-down(sm) {
                // right: -5rem;
                // transform: translateX(60%);
            }
        }

        @include media-breakpoint-down(sm) {
            padding-left: 3rem;
            white-space: break-spaces;
        }
    }

    h2 {
        font-size: 1.6rem;
        line-height: 1.67;
        color: $black;
        margin-bottom: 2rem;
        text-align: left;
        font-weight: 700;
    }

    p,
    div {
        font-size: 1.8rem;
        line-height: 1.5;
        margin-bottom: 3.2rem;
        text-align: left;
    }

    img {
        width: 100%;
        margin-bottom: 2rem;
        object-fit: cover;
    }

    ol,
    ul {
        list-style: auto;
        padding-left: 1.4rem;
    }
}

.bg {
    background-color: $superLightGray;
}

.desc {
    white-space: break-spaces;
}

.videoWrap {
    max-width: 112.5rem;
    margin: auto;
}