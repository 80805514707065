@import '../../styles/variable.scss';
@import '../../styles/mixin.scss';
@import '../../styles/extend.scss';

.wrap {
    @extend %wrap;
    overflow: hidden;

    .row {
        @extend %row;

        position: relative;
        width: 1920px;
        max-width: 100%;
        // margin-left: calc((100vw - 1920px) / 2);

        @include media-breakpoint-down(sm) {
            width: 100%;
            margin-left: 0;

            img {
                width: 100%;
            }
        }
    }

    a {
        display: block;
    }
}

.bannerInfo {
    position: absolute;
    top: 14.1rem;
    left: 50%;
    transform: translateX(-50%);
    color: $white;

    @include media-breakpoint-down(sm) {
        width: 100%;
        margin-left: 0;
        transform: translateX(0);
        top: 4.2rem;
        left: 0;
    }

    h2 {
        font-size: 4.8rem;
        font-weight: 500;
        line-height: 1.21;
        letter-spacing: 1.44px;
        margin-bottom: 2.4rem;
    }

    p {
        text-shadow: 0 3px 3px rgba(0, 0, 0, .16);
        font-size: 1.6rem;
        line-height: 1.5;
        width: 32rem;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 3.2rem;
    }

    span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: fit-content;
        height: 48px;
        margin: auto;
        padding: 13px 36px;
        border-radius: 25px;
        background-color: $primaryBlue;
        line-height: 1.19;
        @extend %button;
    }

}
