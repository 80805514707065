@import './reset.css';
@import './variable.scss';
@import './mixin.scss';
@import './extend.scss';
@import './icomoon.css';
@import './JuliusSansOne-Regular.css';

html {
    scroll-behavior: smooth;
    font-size: 62.5%;
}

body {
    font-size: 1.6rem;
    font-family: $font-family-sans-serif;
    color: $black;
    min-width: 320px;
}

button {
    &.disabled {
        pointer-events: none;
    }
}

a i.icon {
    text-decoration: none;
    color: $black
}

a:focus {
    outline: none;
}

.carousel-root {
    .dot {
        box-shadow: none !important;
        width: 1rem;
        height: 1rem;
        opacity: 1 !important;

        &.selected {
            border: 2px solid $primaryBlue;
            background: $primaryBlue !important;
        }
    }

    // .control-arrow {
        // display: none;
    // }
}

.carousel .thumb.selected, .carousel .thumb:hover {
    border: 2px solid $darkGray;
    border-radius: 8px;
}

.carousel .control-dots {
    bottom: 3rem !important;

    @include media-breakpoint-down(sm) {
        bottom: .6rem !important;
    }
}

.ReactGridGallery {
    @extend %cf;

    .ReactGridGallery_tile {
        background: transparent !important;
    }

    .ReactGridGallery_tile-icon-bar {
        display: none !important;
        opacity: 0 !important;
    }
}