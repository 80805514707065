@font-face {
  font-family: 'icomoon';
  src:
    url('fonts/icomoon.ttf?fqypni') format('truetype'),
    url('fonts/icomoon.woff?fqypni') format('woff'),
    url('fonts/icomoon.svg?fqypni#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-dollar-sign:before {
  content: "\e900";
}
.icon-facebook:before {
  content: "\e908";
}
.icon-info:before {
  content: "\e906";
}
.icon-instagram:before {
  content: "\e901";
}
.icon-link:before {
  content: "\e902";
}
.icon-menu:before {
  content: "\e903";
}
.icon-plus:before {
  content: "\e904";
}
.icon-share:before {
  content: "\e909";
}
.icon-tag:before {
  content: "\e905";
}
.icon-x:before {
  content: "\e907";
}
