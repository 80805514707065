@font-face {
  font-family: 'JuliusSansOne';
  src:
    url('fonts/JuliusSansOne-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.jso-price {
  font-family: 'JuliusSansOne' !important;
  /* letter-spacing: 1px; */
}