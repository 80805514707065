@import '../styles/variable.scss';
@import '../styles/mixin.scss';
@import '../styles/extend.scss';

.wrap {
    @extend %wrap;
    position: relative;

    padding-top: 4rem;
    padding-bottom: 4rem;

    .row {
        @extend %row;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        // flex-direction: column;
        padding-left: 4rem;
        padding-right: 4rem;
    }

    h1 {
        font-size: 2.4rem;
        line-height: 1.67;
        color: $black;
        margin-bottom: 3.2rem;
    }

    h2 {
        font-size: 1.6rem;
        line-height: 1.67;
        color: $black;
        margin-bottom: 2rem;
        text-align: left;
        font-weight: 700;
    }

    p, div {
        font-size: 1.4rem;
        line-height: 1.5;
        margin-bottom: 2.4rem;
        text-align: left;
    }

    img {
        width: 100%;
        margin-bottom: 2rem;
    }

    ol, ul {
        list-style: auto;
        padding-left: 1.4rem;
    }
}
