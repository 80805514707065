@import '../styles/variable.scss';
@import '../styles/mixin.scss';
@import '../styles/extend.scss';

.wrap {
    padding-top: 4rem;
    padding-bottom: 5.6rem;
    @extend %wrap;
    margin-bottom: 0;
    background-color: $lightGray;

    @include media-breakpoint-down(sm) {
        padding-top: 2.4rem;
        padding-bottom: 2.4rem;
    }

    .row {
        @extend %row;
        text-align: left;
    }

    .col {
        padding-left: 2rem;
        padding-right: 2rem;
        position: relative;

        &::before {
            position: absolute;
            left: 0;
            height: 2rem;
            width: 1px;
            background-color: $gray;
            content: "";
        }

        &:first-of-type {
            &::before {
                display: none;
            }
        }

        @include media-breakpoint-down(sm) {
            // margin-left: 1.6rem;
            margin-bottom: 1.6rem;
            width: 100%;
        }
    }

    .copyright {
        width: 100%;
        text-align: center;
        line-height: 1.19;
    }

    .sm {

        margin-top: 1.2rem;

        &>a {
            margin-left: .8rem;
            margin-right: .8rem;
        }
    }


    .foot {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 2rem;
        flex-wrap: wrap;

        @include media-breakpoint-down(sm) {
            justify-content: end;
        }
    }

    a {
        @extend %alinkBtn;
        color: $black;
    }

    span {
        @extend %text-link;
    }

    img {
        display: block;
        width: 3.6rem;
        height: 3.6rem;
        margin-right: 1.6rem;
        @extend %button;
    }

    .iconGroup {
        display: flex;
        align-items: center;
        justify-content: center;

        @include media-breakpoint-down(sm) {
            // justify-content: start;
        }
    }

}

.logo {
    display: block;
    width: 19.1rem;
    height: 4.7rem;

    @include media-breakpoint-down(sm) {
        margin-left: -2rem;
    }

    .img {
        width: 100%;
        height: 100%;
    }
}