%wrap {
    width: 100%;
    margin-bottom: 4rem;
}

%row {
    max-width: $xl;
    margin: auto;
}

%price {
    font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
}

%alinkBtn {
    text-decoration: none;

    // &:active {
    //     background-color: $white;
    // }
}


%h2 {
    font-size: 2.8rem;
    line-height: 1.21;
    color: $black;
    padding-top: 4rem;
    padding-bottom: 2.4rem;

    @include media-breakpoint-down(sm) {
        padding-top: 1.6rem;
        padding-bottom: 1.6rem;
    }
}

%cf {

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }
}

%hoverOpacity {
    &:hover {
        opacity: .8;
    }
}

%hoverOpacityImg {
    &:hover {
        img {
            transition: opacity .5s cubic-bezier(0.39, 0.58, 0.57, 1);
            opacity: .8;
        }
    }
}

%hoverZoomOutImg {
    &:hover {
        img {
            transition: transform .3s cubic-bezier(0.39, 0.58, 0.57, 1);
            transform: scale(1.05);
        }
    }
}


%button {
    transition: opacity .3s cubic-bezier(0.18, 0.85, 0.5, 1.2);

    @extend %hoverOpacity;
    
}

%light-button {
    transition: all .3s cubic-bezier(0.18, 0.85, 0.5, 1.2);

    &:hover {
        color: $white;
        background-color: #39A3FD;
    }
}

%button-disabled {
    background-color: $gray;
}

%text-link {
    &:hover {
        text-decoration: underline;
    }
}
