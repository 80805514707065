.section {
    position: relative;
}

.productWrap {
    padding-top: 6rem;
    margin-bottom: 6rem;
}

.firstWrap {
    margin-top: -4rem;
}

.imgWrap {
    // background-color: #000;

    img {
        // width: 100%;
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
    }
}