@import '../styles/variable.scss';
@import '../styles/mixin.scss';
@import '../styles/extend.scss';

.wrap {
    @extend %wrap;
    position: relative;
    // top: 50vh;
    // transform: translateY(-50%);

    .row {
        @extend %row;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .image {
        margin-bottom: 4rem;
    }

    h1 {
        font-size: 2.4rem;
        line-height: 1.67;
        color: $black;
        margin-bottom: .8rem;
    }

    p {
        line-height: 1.5;
        margin-bottom: 2.4rem;
    }

    .back {
        @extend %alinkBtn;
        width: 29.6rem;
        height: 4.8rem;
        border-radius: 24px;
        background-color: $black;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 5.6rem;
        @extend %button;
    }

}
