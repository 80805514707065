@import '../styles/variable.scss';
@import '../styles/mixin.scss';

.App {
    text-align: center;
    padding-top: $navbarHeight;

    @include media-breakpoint-down(sm) {
        padding-top: $mobileNavbarHeight;
    }
}
