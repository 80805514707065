@import '../../styles/variable.scss';
@import '../../styles/mixin.scss';
@import '../../styles/extend.scss';



.wrap {
    @extend %wrap;
    margin-bottom: 0;
    padding-top: 3.2rem;
    // background-color: rgba($black, 0.8);
    // 漸層背景色由上到下
    background-image: linear-gradient(to bottom, rgba($black, 0.6), rgba($black, 0.5));
    color: $white;

    // display: flex;
    // justify-content: center;
    h2 {
        font-weight: 700;
        font-size: 2.4rem;
        margin-bottom: 3.2rem;
        text-align: center;
    }

    .row {
        @extend %row;
        margin: auto;

        // padding-top: 4rem;
        padding-bottom: 4rem;
        // margin-left: -.8rem;
        // margin-right: -.8rem;

        display: grid;
        grid-template-columns: repeat(4, 1fr);
        // grid-column-gap: 1.6rem;


        @include media-breakpoint-down(sm) {
            padding-top: 0;
            padding-bottom: 0;
            grid-template-columns: repeat(2, 1fr);
            // grid-column-gap: 1.2rem;
            // grid-row-gap: 1.2rem;
        }

    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        // max-width: 42rem;
        // margin-left: .8rem;
        // margin-right: .8rem;

        @include media-breakpoint-down(sm) {
            width: 100%;
            margin: 0;
        }
    }

    .cateWrap {
        position: relative;

        &::after {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba($black, 0.4);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 2rem;
            color: $lightGray;
            font-weight: 700;
            letter-spacing: 3px;
            transition: opacity .5s;
            line-height: 2;
            content: attr(title);
        }

        &:hover {
            &::after {
                opacity: 0;
            }
        }

    }

}