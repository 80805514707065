//
$xs: 375px;
$sm: 640px;
// $md: 768px;
$lg: 1024px;
$xl: 1280px;

// colors
$white: #fff;
$superLightGray: #f7f7f7;
$lightGray: #ebebeb;
$gray: #b5b5b5;
$darkGray: #666;
$black: #292929;
$spGray: #898a90;
$primaryBlue: #39a3fd;
$secondaryBlue: #e5f3ff;
$tertiaryBlue: #1d3f9d;
$stBlue: #6e84ad;
$orange: #e08d52;
$secOrange: #fb8634;
$payment: $black;
$success: green;
$danger: #ff4654;
$purple: #7e5be5;

// Header
$navbarHeight: 90px;
$mobileNavbarHeight: 60px;

// Cards
$card-border-radius: .8rem;

// Buttons
$btn-border-radius: 1.2rem;

// Font family
$font-family-sans-serif: 'Roboto',
'Noto Sans TC',
-apple-system,
system-ui,
BlinkMacSystemFont,
"Segoe UI",
Arial,
sans-serif !default;

// Font size
$font-size-base: 1.6rem;
$h1-font-size: $font-size-base * 1.875;
$h2-font-size: $font-size-base * 1.5;
$h3-font-size: $font-size-base * 1;
$h5-font-size: $font-size-base * 1;
$h6-font-size: $font-size-base;

// Font md size
$h2-font-md-size: $font-size-base * 1.375;

// Forms
$form-text-font-size: $font-size-base;
$form-text-font-weight: null;
$form-text-color: $black;

// Forms Breakpoint
$form-sm: 935px;

// Label
$form-label-font-size: 1.4rem;
$form-label-font-weight: null;
$form-label-color: $black;

// Input
$custom-input-padding-x: 2.4rem;
$custom-input-padding-y: 0;
$custom-input-border-width: 1px;
$custom-input-border-color: $superLightGray;
$custom-input-border-radius: 2.4rem;
$custom-input-background: $superLightGray;

// Select
$custom-select-padding-x: 2.4rem;
$custom-select-padding-y: 0;
$custom-select-border-width: 1px;
$custom-select-border-color: $superLightGray;
$custom-select-border-radius: 2.4rem;
$custom-select-background: $superLightGray;

// Validation
$form-feedback-font-size: 1.3rem;
$form-feedback-valid-color: $success;
$form-feedback-invalid-color: $danger;
$form-feedback-invalid-background: #fff0f3;
// $form-feedback-font-style: $form-text-font-style;