@import '../styles/variable.scss';
@import '../styles/mixin.scss';
@import '../styles/extend.scss';

.wrap {
    @extend %wrap;
    position: fixed;
    top: 0;
    z-index: 16;
    height: $navbarHeight;
    padding: 2.3rem 0;
    background-color: $white;

    &.withShadow {
        box-shadow: 0 3px 6px 0 rgba(114, 114, 114, 0.16);
        opacity: .8;
    }

    @include media-breakpoint-down(sm) {
        height: $mobileNavbarHeight;
        padding: 1.5rem 1.6rem;
    }

    .row {
        @extend %row;
        display: flex;
        justify-content: space-between;
    }

    .leftBox {
        width: 4rem;
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    .rightBox {
        width: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    font-size: $font-size-base;
    padding-top: 1.4rem;

    .subMenu {
        display: none;
        position: absolute;
        width: 13.6rem;
        transform: translate(-50%, 0);
        padding-top: 1.9rem;
        margin-left: 50%;
        background-color: $white;

        li {
            padding: 1.7rem 0;

            a {
                @extend %alinkBtn;
                color: $black;

                &:hover {
                    color: $black;
                }
            }

        }

        &:before {
            display: block;
            height: 5px;
            background-color: $black;
            content: "";
        }
    }

    .menuList {
        position: relative;
        margin-right: 6.4rem;

        transition: color .3s cubic-bezier(0.18, 0.85, 0.5, 1.2);


        &:last-of-type {
            margin-right: 6rem;
        }

        &:hover .subMenu {
            display: block;
        }

        &:hover {
            color: $black;
        }

    }

}

.logo {
    // width: 17.4rem;
    height: 4.4rem;

    @include media-breakpoint-down(sm) {
        // width: 11.8rem;
        height: 3rem;
    }

    .img {
        // width: 100%;
        height: 100%;
    }
    @extend %button;
}

.hamburger {
    padding: .6rem 1rem;
    // margin-top: -.3rem;
    margin-right: -1rem;
    font-size: 2rem;
    @extend %alinkBtn;
}


.menuWrap {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 300;
    width: 100vw;
    height: 100vh;

    .bg {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.65;
        background-color: $black;
        backdrop-filter: blur(50px);
        width: 100%;
        height: 100%;
    }

    .menuBlock {
        position: absolute;
        top: 0;
        right: -80%;
        width: 80%;
        max-width: 30rem;
        height: 100%;
        background-color: $white;

        animation-name: slideLeft;
        animation-delay: .1s;
        animation-duration: .4s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;

        & > ul {
            padding-bottom: 6rem;
        }
    }

    .menuCtrl {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 3.2rem;

        a {
            // background-color: $primaryBlue;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 6rem;
            height: 6rem;
            font-size: 2rem;
            @extend %alinkBtn;
        }
    }

    ul {
        max-height: calc(100vh - 12rem);
        overflow: auto;
        padding-bottom: 2.4rem;
    }

    li {
        width: 100%;

        
    }

    .item {
        height: 6rem;

        a {
            @extend %alinkBtn;
            height: 0rem;
            color: $black;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 2.5rem;
            overflow: hidden;
            animation-name: slideDown;
            animation-delay: .1s;
            animation-duration: .4s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;

            &:active, &:focus {
                color: $black;
            }
        }
    }

}



@keyframes slideDown {
    0% {
        height: 0;
    }

    100% {
        height: 6rem;
    }
}

@keyframes slideLeft {
    0% {
        right: -80%;
    }

    100% {
        right: 0;
    }
}
