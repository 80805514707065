@import '../styles/variable.scss';
@import '../styles/mixin.scss';
@import '../styles/extend.scss';


.wrap {
    @extend %wrap;


    .row {
        @extend %row;

        padding-left: 2rem;
        padding-right: 2rem;

        @include media-breakpoint-down(sm) {}

    }

    h1 {
        font-weight: 700;
        font-size: 1.6rem;
        // margin-top: 3.2rem;
        margin-bottom: 1.2rem;
        margin-bottom: 4rem;
    }

    h2 {
        font-weight: 700;
        font-size: 1.6rem;
        margin-bottom: 1.2rem;
        color: $darkGray;
    }

    h3 {
        font-weight: 500;
        font-size: 1.6rem;
        margin-bottom: 2rem;
    }

    // h2 {
    //     font-weight: 500;
    //     font-size: 1.6rem;
    //     margin-bottom: 2rem;
    // }

    .price {
        // @extend %price;
        // color: $darkGray;
        font-weight: 700;

        &:before {
            content: "$";
            padding-right: .4rem;
        }
    }

    .specialOfferLineThrough {
        text-decoration: line-through;
    }

    .specialOffer {
        color: $danger;
        text-shadow: 0 0 1px $danger;
        padding-left: .4rem;
    }


    img {
        max-width: 60rem;
        margin-bottom: 1.2rem;

        // @include media-breakpoint-down(sm) {
        width: 100%;
        height: 100%;
        // }
    }

    // carousel
    li img {
        margin-bottom: 0rem;
        border-radius: 6px;
    }


    .desc {
        line-height: 1.2;
        letter-spacing: 1px;
        text-align: left;
        max-width: 60rem;
        margin-bottom: 3.2rem;
        font-size: 1.2rem;

        p {
            margin-bottom: 1.2rem;
        }

        li {
            margin-bottom: .4rem
        }

        ul {
            margin-bottom: 2rem
        }

    }

    .detail {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: .8rem;
        // grid-row-gap: 1.2rem;
        padding-left: 1.2rem;
        padding-right: 1.2rem;


        img {
            width: 100%;
            object-fit: contain;
        }
    }

    .placeOrderBtn {
        @extend %button;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 60rem;
        padding: 1.6rem;
        background-color: $payment;
        letter-spacing: 1px;
        color: $white;
        font-weight: 500;
        text-decoration: none;
        border-radius: 4px;
        margin-bottom: 1.2rem;
        font-size: 1.8rem;

        &.btnDisabled {
            background-color: $gray;
            color: $darkGray;
        }
    }

    .hint {
        color: $darkGray;
        font-size: 1.4rem;
    }
}