@import '../../styles/variable.scss';
@import '../../styles/mixin.scss';
@import '../../styles/extend.scss';



.wrap {
    @extend %wrap;
    margin-bottom: 8rem;

    .row {
        @extend %row;

        @include media-breakpoint-down(sm) {}
    }

    h2 {
        font-weight: 700;
        font-size: 2.4rem;
        margin-bottom: 3.2rem;
    }
}

.subTitle {
    display: block;
    padding: 1.2rem 0;
    font-size: 1.4rem;
    font-weight: normal;
}

.prodWrap {

    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;

    @include media-breakpoint-down(lg) {

        // display: flex;
        // justify-content: space-between;
        // align-items: center;
        // flex-wrap: wrap;
        grid-template-columns: repeat(3, 1fr);

    }

    @include media-breakpoint-down(sm) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}

.prod {
    display: inline-block;
    max-width: 20rem;
    margin: auto;

    @extend %hoverZoomOutImg;

    @include media-breakpoint-down(lg) {
        margin: 0 auto 2rem;
    }

    @include media-breakpoint-down(sm) {
        // margin: 2rem 0.6rem;
        // max-width: 16rem;
        margin: auto;
    }

    // display: inline-block;

    a {
        display: flex;
        flex-direction: column;
        text-decoration: none;
        color: $black;
        font-size: 1.4rem;
    }

    img {
        width: 100%;
        height: 100%;
        margin-bottom: 0.8rem;
    }

    .caption {
        margin-bottom: 0.8rem;
        font-size: 1.2rem;
        line-height: 1.5;

        @include media-breakpoint-down(sm) {
            padding-left: 1.6rem;
            padding-right: 1.6rem;
        }
    }

    .sub {
        display: flex;
        justify-content: space-around;
        // color: $darkGray;
        margin-bottom: 0.8rem;
    }

    .spec {
        // margin-bottom: 0.8rem;
    }

    .price {

        // @extend %price;
        // margin-bottom: 0.8rem;
        &:before {
            content: "$";
            padding-right: .4rem;
        }
    }

    .specialOfferLineThrough {
        text-decoration: line-through;
    }

    .specialOffer {
        color: $danger;
        text-shadow: 0 0 1px $danger;
        padding-left: .4rem;
    }

    .imgWrap {
        position: relative;
    }

    .stock {
        position: absolute;
        transform: translateY(-50%);
        background-color: $black;
        opacity: .8;
        color: $white;


        padding: 0.4rem 0.8rem;
        bottom: 0.4rem;
        right: 0.4rem;
        font-size: 1.2rem;
    }

    .comingSoon {
        position: absolute;
        transform: translateY(-50%);
        background-color: $tertiaryBlue;
        opacity: .8;
        color: $white;

        padding: 0.4rem 0.8rem;
        bottom: 0.4rem;
        right: 0.4rem;
        font-size: 1.2rem;

        border-radius: 4px;
    }
}